import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { sample, compact } from 'lodash'
import { Element, scroller } from 'react-scroll';
import moment from 'moment'

import { Layout, Block } from '../components'
import { container, padding, bgImage, hoverState, pagePadding, pageHeading, blockPadding, breadcrumb } from '../styles/global'
import { legacyHeading1, legacyHeading2, subheading, h4, body } from '../styles/type'
import { yellow, slateGrey } from '../styles/colours'
import { media } from '../styles/utils'
import { renderPost } from '../components/Block/templates/BlockGridItem'

const News = (props) => {
	const { data } = props.pageContext
	const [activeSlide, setActiveSlide] = useState(null)

	const renderHero = () => {
		if (!data?.hero_slider) return

		return (
			<Hero
				name={'hero'}
			>
				{ data?.hero_caption &&
					<Info>
						<Heading
							as={'h2'}
							className={data.dark_caption ? 'dark' : ''}
							dangerouslySetInnerHTML={{__html: data?.hero_caption}}
						/>
					</Info>
				}
				<Block
					layout={'slider'}
					slides={data.hero_slider}
					sliderStyles={sliderStyles}
					activeSlide={activeSlide}
					lastBlock
					display
					controls
				/>				
			</Hero>
		)
	}	

	const renderBlocks = () => {
        if (!data?.blocks) return;

		console.log('blocks', data.blocks)
        
		return data?.blocks.map((block, i) => { 
			return (
				<Block
					layout={block.acf_fc_layout}
					display={block.display}
					key={i}
					{...block}
				/>
			)
		})
	}

	const renderRelatedStories = () => {
		if(!data?.related_stories) return

		return (
			<Block
				layout={'related'}
				heading={'Related Stories'}
			 	data={data?.related_stories}
				display={data?.display_related_stories}
				contentType={'journal'}
			/>
		)
	}

	const renderRelatedProjects = () => {
		if (!data?.related_projects) return

		return (
			<Block
				layout={'related'}
				heading={'Related Projects'}
			 	data={data?.related_projects}
				display={data?.display_related_projects}
				contentType={'projects'}
			/>
		)
	}

	return (
		<Layout
			meta={data.seo}
		>
			<Wrapper>
				<Block
					layout={'page_title'}
					breadcrumb={'Journal'}
					date={moment.unix(data.publishedDateUnix).format('DD.MM.YYYY')}
					title={data.title}
					textColor={'black'}
					h1
					display
				/>

				{renderHero()}
				<Container>
					{renderBlocks()}
				</Container>
				{renderRelatedStories()}
				{renderRelatedProjects()}
			</Wrapper>
		</Layout>	
	)
}

// Shared

const Heading = styled.h2``
const Subheading = styled.h3``
const Description = styled.div``
const Info = styled.div``

const Item = styled.div``
const Items = styled.div``

const Image = styled(ProgressiveImage)`
	overflow: hidden;
`

const BGImage = styled.div`
	background-image: url(${props => props.image});  
	${bgImage};
	transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	overflow-x: hidden;

	> *:first-child {
		${pagePadding}
	}
`

const Wrap = styled.div`
	padding-top: 180px !important;
	width: 100%;
	${blockPadding};

	${media.phone`
		padding-top: 130px !important;
	`}
`

const Container = styled.div`
	${container}
	${padding}
	${blockPadding}

	display: flex;
	flex-direction: column;
	max-width: 100%;
`

// Breadcrumb

const Breadcrumb = styled.div`
	${breadcrumb}
`

// Hero

const Hero = styled.div`
	margin-bottom: 89px;
	position: relative;

	${media.phone`
		margin-bottom: 42px;
	`}

	${Info}{
		display: flex;
		flex-direction: column;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
		${blockPadding}
		margin-bottom: 40px;
		${media.phone`
			margin-bottom: 20px;
		`}

		${Heading} {
			color: white;
			font-family: StyreneA;
    	font-size: 18px;
    	line-height: 1.3;

			&.dark {
				color: black;
			}

			${media.phone`
				font-size: 16px;
			`}
		}

		${Subheading} {
			${body}
			color: white;
			padding-bottom: 4px;
	
			${media.phone`
				display: none;
			`}
		}
	}
`

// Slide

const Slide = styled.div`
	display: flex;

	${BGImage} {
		height: 100%;
		flex: 1;
	}
`

const sliderStyles = css`
	height: 100vh;
	width: 100vw;

	${media.phone`
		height: 66vw;
	`}
`

// Top Content

const TopContent = styled.div`
	display: flex;
	flex-direction: column;
	padding-bottom: 80px;

	${media.phone`
		padding-bottom: 52px;
	`}

	${Subheading} {
		${body};
		margin-bottom: 20px;		
	}

	${Heading} {
		font-family: 'Formula';
		font-size: 72px;
		line-height: 1;
		letter-spacing: 0.05em;
		text-transform: uppercase;
		max-width: 1000px;

		${media.phone`
			font-size: 40px;
			line-height: 95%;
		`}
	}
`

// Content

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	${Description} {
		margin-bottom: 70px;

		${media.phone`
			margin-bottom: 30px;
		`}

		&, p {
			${legacyHeading2}
		}
	}
`

// Gallery

const Gallery = styled.div`
	margin-top: 128px;
	display: flex;
	flex-flow: row wrap;

	${Item} {
		flex: 0 1 auto;
		max-width: calc(33.3% - 64px);
		margin: 0 64px 64px 0;
		width: 100%;
		cursor: pointer;
		object-fit: contain;
	}

	${media.phone`
		display: none;
	`}
`

// Related 

const Related = styled.div`
	margin-top: 129px;
	display: flex;
	flex-direction: column;
	width: 100%;

	${Heading} {
		${padding}
	}

	${Items} {
		margin-top: 31px;
		display: flex;

		> * {
			height: 416px;
			background-color: ${slateGrey};
			flex: 1;
		}
	}

	${media.phone`
		margin-top: 111px;

		${Heading} {
			font-size: 12px;
			line-height: 1.25;
			padding: 0 16px;
		}

		${Items} {
			margin-top: 20px;
			flex-direction: column;

			> * {
				height: auto;
				width: 100vw;
				flex: 1 0 256px;
			}
		}
	`}
`

export default News